.MuiCollapse-wrapper {
  .successSnackbarBody {
    background: #3eb650;

    .MuiSvgIcon-root {
      margin-right: 10px;
    }
  }

  .errorSnackbarBody {
    background: #e15321;

    .MuiSvgIcon-root {
      margin-right: 10px;
    }
  }
}
